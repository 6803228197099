import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/anthony/Projects/blog/src/components/blog-post-layout.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`The purpose of this HTML is to help determine what default settings are with CSS and to make sure that all possible HTML Elements are included in this HTML so as to not miss any possible Elements when designing a site.`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h1" components={components}>{`Heading 1`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Heading 2`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Heading 3`}</MDXTag>
      <MDXTag name="h4" components={components}>{`Heading 4`}</MDXTag>
      <MDXTag name="h5" components={components}>{`Heading 5`}</MDXTag>
      <MDXTag name="h6" components={components}>{`Heading 6`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{
          "href": "#wrapper"
        }}>{`[`}{`top`}{`]`}</MDXTag></MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h2" components={components}>{`Paragraph`}</MDXTag>
      <MDXTag name="p" components={components}>{`Lorem ipsum dolor sit amet, `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "#",
          "title": "test link"
        }}>{`test link`}</MDXTag>{` adipiscing elit. Nullam dignissim convallis est. Quisque aliquam. Donec faucibus. Nunc iaculis suscipit dui. Nam sit amet sem. Aliquam libero nisi, imperdiet at, tincidunt nec, gravida vehicula, nisl. Praesent mattis, massa quis luctus fermentum, turpis mi volutpat justo, eu volutpat enim diam eget metus. Maecenas ornare tortor. Donec sed tellus eget sapien fringilla nonummy. Mauris a ante. Suspendisse quam sem, consequat at, commodo vitae, feugiat in, nunc. Morbi imperdiet augue quis tellus.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Lorem ipsum dolor sit amet, `}<MDXTag name="em" components={components} parentName="p">{`emphasis`}</MDXTag>{` consectetuer adipiscing elit. Nullam dignissim convallis est. Quisque aliquam. Donec faucibus. Nunc iaculis suscipit dui. Nam sit amet sem. Aliquam libero nisi, imperdiet at, tincidunt nec, gravida vehicula, nisl. Praesent mattis, massa quis luctus fermentum, turpis mi volutpat justo, eu volutpat enim diam eget metus. Maecenas ornare tortor. Donec sed tellus eget sapien fringilla nonummy. Mauris a ante. Suspendisse quam sem, consequat at, commodo vitae, feugiat in, nunc. Morbi imperdiet augue quis tellus.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{
          "href": "#wrapper"
        }}>{`[`}{`top`}{`]`}</MDXTag></MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h2" components={components}>{`List Types`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Definition List`}</MDXTag>
      <MDXTag name="p" components={components}>{`Definition List Title`}</MDXTag>
      <MDXTag name="p" components={components}>{`This is a definition list division.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Ordered List`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol">{`List Item 1`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`List Item 2`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`List Item 3`}</MDXTag>
      </MDXTag>
      <MDXTag name="h3" components={components}>{`Unordered List`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`List Item 1`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`List Item 2`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`List Item 3`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{
          "href": "#wrapper"
        }}>{`[`}{`top`}{`]`}</MDXTag></MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h2" components={components}>{`Forms`}</MDXTag>
      <MDXTag name="p" components={components}>{`Legend`}</MDXTag>
      <MDXTag name="p" components={components}>{`Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Nullam dignissim convallis est. Quisque aliquam. Donec faucibus. Nunc iaculis suscipit dui. Nam sit amet sem. Aliquam libero nisi, imperdiet at, tincidunt nec, gravida vehicula, nisl. Praesent mattis, massa quis luctus fermentum, turpis mi volutpat justo, eu volutpat enim diam eget metus.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Form Element`}</MDXTag>
      <MDXTag name="p" components={components}>{`Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Nullam dignissim convallis est. Quisque aliquam. Donec faucibus. Nunc iaculis suscipit dui.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Text Field:`}</MDXTag>
      <MDXTag name="p" components={components}>{`Text Area:`}</MDXTag>
      <MDXTag name="p" components={components}>{`Select Element:`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Option 1Option 2Option 3 Option 1Option 2Option 3`}</MDXTag>
      <MDXTag name="p" components={components}>{`Radio Buttons:`}</MDXTag>
      <MDXTag name="p" components={components}>{`Radio 1`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Radio 2`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Radio 3`}</MDXTag>
      <MDXTag name="p" components={components}>{`Checkboxes:`}</MDXTag>
      <MDXTag name="p" components={components}>{`Radio 1`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Radio 2`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`Radio 3`}</MDXTag>
      <MDXTag name="p" components={components}>{`Password:`}</MDXTag>
      <MDXTag name="p" components={components}>{`File Input:`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{
          "href": "#wrapper"
        }}>{`[`}{`top`}{`]`}</MDXTag></MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h2" components={components}>{`Tables`}</MDXTag>
      <MDXTag name="p" components={components}>{`Table Header 1`}</MDXTag>
      <MDXTag name="p" components={components}>{`Table Header 2`}</MDXTag>
      <MDXTag name="p" components={components}>{`Table Header 3`}</MDXTag>
      <MDXTag name="p" components={components}>{`Division 1`}</MDXTag>
      <MDXTag name="p" components={components}>{`Division 2`}</MDXTag>
      <MDXTag name="p" components={components}>{`Division 3`}</MDXTag>
      <MDXTag name="p" components={components}>{`Division 1`}</MDXTag>
      <MDXTag name="p" components={components}>{`Division 2`}</MDXTag>
      <MDXTag name="p" components={components}>{`Division 3`}</MDXTag>
      <MDXTag name="p" components={components}>{`Division 1`}</MDXTag>
      <MDXTag name="p" components={components}>{`Division 2`}</MDXTag>
      <MDXTag name="p" components={components}>{`Division 3`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{
          "href": "#wrapper"
        }}>{`[`}{`top`}{`]`}</MDXTag></MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h2" components={components}>{`Misc Stuff – abbr, acronym, pre, code, sub, sup, etc.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Lorem superscript dolor subscript amet, consectetuer adipiscing elit. Nullam dignissim convallis est. Quisque aliquam. cite. Nunc iaculis suscipit dui. Nam sit amet sem. Aliquam libero nisi, imperdiet at, tincidunt nec, gravida vehicula, nisl. Praesent mattis, massa quis luctus fermentum, turpis mi volutpat justo, eu volutpat enim diam eget metus. Maecenas ornare tortor. Donec sed tellus eget sapien fringilla nonummy. NBA Mauris a ante. Suspendisse quam sem, consequat at, commodo vitae, feugiat in, nunc. Morbi imperdiet augue quis tellus. AVE`}</MDXTag>
      <MDXTag name="p" components={components}>{`Lorem ipsum dolor sit amet,
consectetuer adipiscing elit.
Nullam dignissim convallis est.
Quisque aliquam. Donec faucibus.
Nunc iaculis suscipit dui.
Nam sit amet sem.
Aliquam libero nisi, imperdiet at,
tincidunt nec, gravida vehicula,
nisl.
Praesent mattis, massa quis
luctus fermentum, turpis mi
volutpat justo, eu volutpat
enim diam eget metus.
Maecenas ornare tortor.
Donec sed tellus eget sapien
fringilla nonummy.
NBA
Mauris a ante. Suspendisse
quam sem, consequat at,
commodo vitae, feugiat in,
nunc. Morbi imperdiet augue
quis tellus.`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
        {`AVE`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`“This stylesheet is going to help so freaking much.”`}<MDXTag name="br" components={components} parentName="p"></MDXTag>
          {`-`}{`Blockquote`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{
          "href": "#wrapper"
        }}>{`[`}{`top`}{`]`}</MDXTag></MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    